export const fonts = {
  TTHOVES: 'TTHoves',
  ROBOTO: 'Roboto',
  GEORGIA: 'georgia',
  INTER: 'Inter',
  SOURCE_SANS_PRO: 'Source Sans Pro',
  regular: 'Source Sans Pro',
  headline: 'georgia',
};

export type FontFamily = keyof typeof fonts;
